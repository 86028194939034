.navbar-wrap {
  height: var(--nav-height);
  position: sticky;
  top: 0;
  z-index: var(--navbar-z-index);
  margin-top: calc(-1 * var(--nav-height));
  padding: 0 1rem;
  transition: all 0.3s ease-in-out;
}

.navbar {
  max-width: var(--content-mw);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-wrap {
  cursor: pointer;
}

.open-icon-wrap {
  height: var(--nav-height);
  display: none;
}

.nav-links {
  display: flex;
  align-items: center;
  text-align: center;
  list-style: none;
}

.link-wrap {
  height: var(--nav-height);
}

.link {
  height: 100%;
  margin-left: 1rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  border-bottom: 3px solid transparent;
  color: var(--text-light);
  text-decoration: none;
  letter-spacing: 1.1px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.link:hover {
  color: var(--primary-yellow);
}

.link-active {
  color: var(--primary-yellow);
  border-bottom: 3px solid var(--primary-yellow);
}

.resume-link {
  height: 100%;
  margin-left: 2rem;
  display: flex;
  align-items: center;
  border-bottom: 3px solid transparent;
  color: var(--text-light);
  text-decoration: underline;
  letter-spacing: 1.1px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.resume-link:hover {
  color: var(--primary-yellow);
}

@media only screen and (max-width: 768px) {
  .navbar-wrap:hover {
    background-color: transparent;
  }

  .nav-links {
    display: none;
  }

  .open-icon-wrap {
    display: flex;
    align-items: center;
    background: transparent;
  }

  .open-icon {
    display: inline-block;
    color: var(--text-light);
    cursor: pointer;
  }
}