@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+Expanded&display=swap');

:root {
  --content-mw: 1100px;
  --primary-blue: #112554;
  --primary-yellow: #d19106;
  --bg-light: #e7e9ed;
  --text-light: #e7e9ed;
  --text-grey: #b7bdcb;
  --nav-height: 80px;
  --hero-z-index: 1;
  --hero-content-z-index: 2;
  --contact-z-index: 2;
  --navbar-z-index: 5;
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
}

body,
html {
  background-color: var(--bg-light);
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0;
  outline: none;
  font-family: 'Encode Sans Expanded', sans-serif;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.section-title {
  margin-bottom: 1rem;
  color: var(--primary-yellow);
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1.4px;
}

.section-header {
  font-size: 3rem;
}

.section-text {
  font-size: 1.5rem;
}

.section-header,
.section-text {
  color: var(--primary-blue);
  margin-bottom: 1rem;
}
