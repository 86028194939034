.project-wrap {
  background: var(--bg-light);
  padding: 0 1rem;
}

.projects {
  padding: 3rem 0;
  margin: auto;
  max-width: var(--content-mw);
}

.projects-card-wrap {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 1rem;
}

.project-card {
  color: var(--primary-blue);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  grid-template-areas: 'img text';
}

.project-col-img {
  grid-area: img;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.project-img-wrap {
  max-width: 100%;
}

.project-img {
  max-width: 400px;
  border-radius: 8px;
}

.project-col-text {
  grid-area: text;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-name {
  margin-bottom: 1rem;
  font-size: 24px;
}

.project-desc {
  margin-bottom: 8px;
  line-height: 24px;
}

.project-used {
  color: #707c98;
  font-weight: bold;
  margin-bottom: 8px;
}

.github-link {
  color: #112554;
  letter-spacing: 1.1px;
  text-decoration: none;
  border-radius: 12px;
  background: #cfd3dc;
  transition: all 0.2s ease-in-out;
}

.github-link:hover {
  background: #babdc6;
}

.project-card:nth-child(even) {
  grid-template-areas: 'text img';
  padding: 0 4px;
  background: rgba(207,211,220,0.4);
  border-radius: 8px;
}

@media only screen and (max-width: 768px) {
  .projects-card-wrap {
    grid-auto-rows: auto;
    gap: 2rem;
  }

  .project-card {
    display: flex;
    flex-direction: column;
  }

  .project-img-wrap {
    display: flex;
    justify-content: center;
    padding-top: 6px;
  }

  .project-img {
    max-width: 400px;
  }
}

@media only screen and (max-width: 450px) {
  .project-img {
    max-width: 300px;
    max-height: 300px;
  }
}

@media only screen and (max-width: 325px) {
  .project-img {
    max-width: 250px;
    max-height: 250px;
  }
}

.popup-image {
  z-index: 12;
}