.hero-wrap {
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary-blue);
  z-index: var(--hero-z-index);
}

.hero-content {
  max-width: 600px;
  z-index: var(--hero-content-z-index);
  margin: 0 0.5rem;
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  border: 2px solid var(--primary-yellow);
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}

.hero-content:hover {
  background: var(--primary-blue);
}

.hero-greeting {
  text-align: right;
  font-size: 24px;
  color: var(--primary-yellow);
  letter-spacing: 2px;
}

.hero-name {
  margin-top: 4px;
  text-align: right;
  font-size: 32px;
  color: var(--text-grey);
  letter-spacing: 1.5px;
}

.hero-intro {
  margin: 1rem 0;
  text-align: center;
  font-size: 22px;
  color: var(--text-light);
  letter-spacing: 1.5px;
}

.social-icons {
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}

.social-link {
  transition: all 0.2s ease-in-out;
}

.social-link:hover {
  transform: scale(1.2);
}

@media only screen and (max-width: 576px) {
  .hero-greeting {
    text-align: center;
  }
  
  .hero-name {
    text-align: center;
  }
}