.about-wrap {
  background: var(--bg-light);
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  padding: 0 1rem;
}

.about {
  padding: 3rem 0;
  max-width: var(--content-mw);
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.about-col-left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-headshot {
  max-width: 400px;
  border-radius: 8px;
  box-shadow: 8px 8px 5px rgba(17,37,84,0.4);
}

@media only screen and (max-width: 768px) {
  .about {
    grid-template-columns: 1fr;
  }

  .img-headshot {
    max-width: 65%;
  }

  .about-col-right {
    padding-top: 1rem;
  }
}