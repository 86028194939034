@import url(https://fonts.googleapis.com/css2?family=Encode+Sans+Expanded&display=swap);
:root {
  --content-mw: 1100px;
  --primary-blue: #112554;
  --primary-yellow: #d19106;
  --bg-light: #e7e9ed;
  --text-light: #e7e9ed;
  --text-grey: #b7bdcb;
  --nav-height: 80px;
  --hero-z-index: 1;
  --hero-content-z-index: 2;
  --contact-z-index: 2;
  --navbar-z-index: 5;
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
}

body,
html {
  background-color: #e7e9ed;
  background-color: var(--bg-light);
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0;
  outline: none;
  font-family: 'Encode Sans Expanded', sans-serif;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.section-title {
  margin-bottom: 1rem;
  color: #d19106;
  color: var(--primary-yellow);
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1.4px;
}

.section-header {
  font-size: 3rem;
}

.section-text {
  font-size: 1.5rem;
}

.section-header,
.section-text {
  color: #112554;
  color: var(--primary-blue);
  margin-bottom: 1rem;
}

.navbar-wrap {
  height: var(--nav-height);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: var(--navbar-z-index);
  margin-top: calc(-1 * var(--nav-height));
  padding: 0 1rem;
  transition: all 0.3s ease-in-out;
}

.navbar {
  max-width: var(--content-mw);
  margin: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.logo-wrap {
  cursor: pointer;
}

.open-icon-wrap {
  height: var(--nav-height);
  display: none;
}

.nav-links {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  list-style: none;
}

.link-wrap {
  height: var(--nav-height);
}

.link {
  height: 100%;
  margin-left: 1rem;
  padding: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 3px solid transparent;
  color: var(--text-light);
  text-decoration: none;
  letter-spacing: 1.1px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.link:hover {
  color: var(--primary-yellow);
}

.link-active {
  color: var(--primary-yellow);
  border-bottom: 3px solid var(--primary-yellow);
}

.resume-link {
  height: 100%;
  margin-left: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 3px solid transparent;
  color: var(--text-light);
  text-decoration: underline;
  letter-spacing: 1.1px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.resume-link:hover {
  color: var(--primary-yellow);
}

@media only screen and (max-width: 768px) {
  .navbar-wrap:hover {
    background-color: transparent;
  }

  .nav-links {
    display: none;
  }

  .open-icon-wrap {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    background: transparent;
  }

  .open-icon {
    display: inline-block;
    color: var(--text-light);
    cursor: pointer;
  }
}
.sidebar-wrap {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  padding: 1rem 0;
  background-color: var(--primary-blue);
}

.close-icon {
  position: fixed;
  top: 25px;
  right: 1rem;
  color: var(--text-light);
  cursor: pointer;
}

.close-icon:hover {
  color: var(--primary-yellow);
}

.sidebar {
  margin-top: 4rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  list-style: none;
}

.sidebar-link-wrap {
  margin: 0.5rem auto;
}

.sidebar-link {
  font-size: 1.25rem;
  color: var(--text-light);
  text-decoration: none;
  letter-spacing: 1.1px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.sidebar-link:hover {
  color: var(--primary-yellow);
}

.sidebar-link-active {
  color: var(--primary-yellow);
}

.resume-sidebar-link {
  font-size: 1.25rem;
  color: var(--text-light);
  text-decoration: underline;
  letter-spacing: 1.1px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.resume-sidebar-link:hover {
  color: var(--primary-yellow);
}
.hero-wrap {
  height: 100vh;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background: var(--primary-blue);
  z-index: var(--hero-z-index);
}

.hero-content {
  max-width: 600px;
  z-index: var(--hero-content-z-index);
  margin: 0 0.5rem;
  padding: 12px 24px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border: 2px solid var(--primary-yellow);
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}

.hero-content:hover {
  background: var(--primary-blue);
}

.hero-greeting {
  text-align: right;
  font-size: 24px;
  color: var(--primary-yellow);
  letter-spacing: 2px;
}

.hero-name {
  margin-top: 4px;
  text-align: right;
  font-size: 32px;
  color: var(--text-grey);
  letter-spacing: 1.5px;
}

.hero-intro {
  margin: 1rem 0;
  text-align: center;
  font-size: 22px;
  color: var(--text-light);
  letter-spacing: 1.5px;
}

.social-icons {
  margin: auto;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  list-style: none;
}

.social-link {
  transition: all 0.2s ease-in-out;
}

.social-link:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

@media only screen and (max-width: 576px) {
  .hero-greeting {
    text-align: center;
  }
  
  .hero-name {
    text-align: center;
  }
}
.about-wrap {
  background: var(--bg-light);
  overflow-x: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0 1rem;
}

.about {
  padding: 3rem 0;
  max-width: var(--content-mw);
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  gap: 1rem;
}

.about-col-left {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.img-headshot {
  max-width: 400px;
  border-radius: 8px;
  box-shadow: 8px 8px 5px rgba(17,37,84,0.4);
}

@media only screen and (max-width: 768px) {
  .about {
    grid-template-columns: 1fr;
  }

  .img-headshot {
    max-width: 65%;
  }

  .about-col-right {
    padding-top: 1rem;
  }
}
.skills-wrap {
  background: var(--primary-blue);
  overflow-x: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0 1rem;
}

.skills {
  padding: 3rem 0;
  max-width: var(--content-mw);
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  gap: 1rem;
}

.skill-header {
  margin-bottom: 0.5rem;
  font-size: 2rem;
  color: var(--text-grey);
}

.skill-text {
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  color: var(--text-light);
}

.skills-col-right {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  list-style: none;
}

.bar {
  margin: 8px 0;
  padding: 4px 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border: 1px solid var(--primary-yellow);;
  border-radius: 8px;
}

.bar-name {
  font-size: 20px;
  color: var(--text-grey);
}

.bar-score {
  font-size: 20px;
  color: var(--text-light);
}

@media only screen and (max-width: 768px) {
  .skills {
    grid-template-columns: 1fr;
  }

  .skills-col-right {
    padding-top: 1rem;
  }
}


.project-wrap {
  background: var(--bg-light);
  padding: 0 1rem;
}

.projects {
  padding: 3rem 0;
  margin: auto;
  max-width: var(--content-mw);
}

.projects-card-wrap {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 1rem;
  gap: 1rem;
}

.project-card {
  color: var(--primary-blue);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-areas: 'img text';
}

.project-col-img {
  grid-area: img;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.project-img-wrap {
  max-width: 100%;
}

.project-img {
  max-width: 400px;
  border-radius: 8px;
}

.project-col-text {
  grid-area: text;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.project-name {
  margin-bottom: 1rem;
  font-size: 24px;
}

.project-desc {
  margin-bottom: 8px;
  line-height: 24px;
}

.project-used {
  color: #707c98;
  font-weight: bold;
  margin-bottom: 8px;
}

.github-link {
  color: #112554;
  letter-spacing: 1.1px;
  text-decoration: none;
  border-radius: 12px;
  background: #cfd3dc;
  transition: all 0.2s ease-in-out;
}

.github-link:hover {
  background: #babdc6;
}

.project-card:nth-child(even) {
  grid-template-areas: 'text img';
  padding: 0 4px;
  background: rgba(207,211,220,0.4);
  border-radius: 8px;
}

@media only screen and (max-width: 768px) {
  .projects-card-wrap {
    grid-auto-rows: auto;
    grid-gap: 2rem;
    gap: 2rem;
  }

  .project-card {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .project-img-wrap {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    padding-top: 6px;
  }

  .project-img {
    max-width: 400px;
  }
}

@media only screen and (max-width: 450px) {
  .project-img {
    max-width: 300px;
    max-height: 300px;
  }
}

@media only screen and (max-width: 325px) {
  .project-img {
    max-width: 250px;
    max-height: 250px;
  }
}

.popup-image {
  z-index: 12;
}
.contact-wrap {
  background: var(--primary-blue);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0 1rem;
}

.contact {
  padding: 3rem 0;
  max-width: var(--content-mw);
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
}

.contact-left {
  grid-area: 1 / 1 / 2 / 2;
  z-index: var(--contact-z-index);
}

.quote-wrap {
  width: 80%;
  margin: 3rem auto;
  color: var(--text-grey);
  text-align: center;
}

.quote {
  margin-bottom: 1rem;
}

.contact-text-wrap {
  max-width: 500px;
}

.contact-header {
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-size: 2rem;
  color: var(--text-grey);
}

.contact-subhead {
  margin-bottom: 3.5rem;
  font-size: 1.25rem;
  line-height: 30px;
  color: var(--text-light);
}

.get-in-touch {
  padding: 6px 12px;
  margin-top: 3rem;
  text-decoration: none;
  font-size: 1.25rem;
  color: var(--text-light);
  border: 1px solid var(--text-light);
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
}

.get-in-touch:hover {
  border: 1px solid #d19106;
  color: #d19106;
}

.contact-right {
  grid-area: 1 / 1 / 2 / 2;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.contact-right img {
  max-width: 600px;
  width: 80%;
  opacity: 0.2;
}

@media only screen and (max-width: 768px) {
  
}
