.contact-wrap {
  background: var(--primary-blue);
  display: flex;
  justify-content: center;
  padding: 0 1rem;
}

.contact {
  padding: 3rem 0;
  max-width: var(--content-mw);
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
}

.contact-left {
  grid-area: 1 / 1 / 2 / 2;
  z-index: var(--contact-z-index);
}

.quote-wrap {
  width: 80%;
  margin: 3rem auto;
  color: var(--text-grey);
  text-align: center;
}

.quote {
  margin-bottom: 1rem;
}

.contact-text-wrap {
  max-width: 500px;
}

.contact-header {
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-size: 2rem;
  color: var(--text-grey);
}

.contact-subhead {
  margin-bottom: 3.5rem;
  font-size: 1.25rem;
  line-height: 30px;
  color: var(--text-light);
}

.get-in-touch {
  padding: 6px 12px;
  margin-top: 3rem;
  text-decoration: none;
  font-size: 1.25rem;
  color: var(--text-light);
  border: 1px solid var(--text-light);
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
}

.get-in-touch:hover {
  border: 1px solid #d19106;
  color: #d19106;
}

.contact-right {
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-right img {
  max-width: 600px;
  width: 80%;
  opacity: 0.2;
}

@media only screen and (max-width: 768px) {
  
}