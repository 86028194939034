.sidebar-wrap {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  padding: 1rem 0;
  background-color: var(--primary-blue);
}

.close-icon {
  position: fixed;
  top: 25px;
  right: 1rem;
  color: var(--text-light);
  cursor: pointer;
}

.close-icon:hover {
  color: var(--primary-yellow);
}

.sidebar {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  list-style: none;
}

.sidebar-link-wrap {
  margin: 0.5rem auto;
}

.sidebar-link {
  font-size: 1.25rem;
  color: var(--text-light);
  text-decoration: none;
  letter-spacing: 1.1px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.sidebar-link:hover {
  color: var(--primary-yellow);
}

.sidebar-link-active {
  color: var(--primary-yellow);
}

.resume-sidebar-link {
  font-size: 1.25rem;
  color: var(--text-light);
  text-decoration: underline;
  letter-spacing: 1.1px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.resume-sidebar-link:hover {
  color: var(--primary-yellow);
}