.skills-wrap {
  background: var(--primary-blue);
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  padding: 0 1rem;
}

.skills {
  padding: 3rem 0;
  max-width: var(--content-mw);
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.skill-header {
  margin-bottom: 0.5rem;
  font-size: 2rem;
  color: var(--text-grey);
}

.skill-text {
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  color: var(--text-light);
}

.skills-col-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style: none;
}

.bar {
  margin: 8px 0;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--primary-yellow);;
  border-radius: 8px;
}

.bar-name {
  font-size: 20px;
  color: var(--text-grey);
}

.bar-score {
  font-size: 20px;
  color: var(--text-light);
}

@media only screen and (max-width: 768px) {
  .skills {
    grid-template-columns: 1fr;
  }

  .skills-col-right {
    padding-top: 1rem;
  }
}

